import { VStack, Text, Box, Flex, HStack, UnorderedList, ListItem, Center, Icon, Button } from "@chakra-ui/react";
import { FaCheckCircle, FaQuoteLeft } from "react-icons/fa";


const features = [{
	text: "Real time signals via Telegram optimized by AI.",
	active: true,
}, {
	text: "Generate arbitrage routes with just trade pair and volume.",
	active: true,
}, {
	text: "Access to our Discord community for discussion and advice with priority support.",
	active: true,
}, {
	text: "Signals via Webhook for automatic integration to your API or Services.",
	active: false,
}, {
	text: "1 hours per month of technical support and personalized advice.",
	active: false,
},
//  {
// 	text: "Full arbitrage trade management by us.",
// 	active: false,
// },
{
	text: "Detailed performance reports and arbitrage insights.",
	active: false
}];

export function Pricing(): JSX.Element {
	return (
		<VStack bgGradient="linear(to-b, rgba(255, 255, 255, 0.13) 0%, rgba(149, 165, 166, 0.15) 100%)" pl={5} pr={5} w={"full"} pt={"20!"} borderBottomColor={"border"} borderBottomWidth={"1px"}>
			<Text
				lineHeight={"24px"}
				fontSize={["3xl", "4xl"]}
				color={"textPrimary"}
			>
				Pricing
			</Text>
			<Flex mt={"20!"} mb={"20!"} w={"full"} alignItems={"center"} justifyContent={"space-around"} direction={["column", "column", "row"]}>
				<Flex flex={1} justifyContent={"center"}>
					<VStack flex={0.6} alignItems={"start"} borderColor={"border"} borderWidth={"1px"} px={8} py={6} borderRadius={15}>
						<Box>
							<Icon color={"highlight"} as={FaQuoteLeft} boxSize={10} />
						</Box>
						<Text color={"textPrimary"} fontSize={"xl"} mt={"5!"}>
							As a newcomer to arbitrage, this service was a game-changer. Real-time signals boosted my confidence and profits.
						</Text>
						<Box mt={"7!"}>
							<Text color={"support"} fontSize={"xs"} fontWeight={"medium"}>
								Jordan Kim, Canada
							</Text>
							<Text color={"support"} fontSize={"2xs"} mt={1}>
								On discovering the power of timely information.
							</Text>
						</Box>

					</VStack>
				</Flex>

				{/* PLAN */}
				<VStack flex={0.85} w={"60"} bgColor={"textPrimary"} borderRadius={20} px={10} py={8} >
					{/* HEADER */}
					<VStack alignItems={"start"}>
						<Box borderRadius={5} bgColor="highlight" px={3} py={2}>
							<Text color={"textPrimary"} fontWeight={"semibold"} fontSize={"sm"}>
								UNIQUE
							</Text>
						</Box>
						<Text pt={2} color={"white"} fontWeight={"light"} fontSize={"md"}>
							For seasoned traders seeking precision and enhanced control, offering real-time signals, seamless webhook integration, and access to a community of community.
						</Text>
					</VStack>

					<Box w={"full"}>
						<Box mt={4} height={"1px"} bgColor={"rgba(149,165,166,0.5)"} w={"full"} />
					</Box>

					{/* PRICE */}
					<HStack justifyContent={"space-between"} width={"full"}>
						<VStack alignItems={"start"} spacing={-2}>
							<Text fontSize={"6xl"} fontWeight={"semibold"} color={"white"}>
								$10
							</Text>
							<Text fontSize={"sm"} fontWeight={"semibold"} color={"rgba(255,255,255,0.5)"}>
								Per member, per Month
							</Text>
						</VStack>

						<Center height={20}>
							<Box mt={4} width={"1px"} bgColor={"rgba(149,165,166,0.5)"} h={"full"} />
							{/* <Box mt={4} width={"1px"} bgColor={"rgba(255,255,255,0.2)"} h={"full"} /> */}
						</Center>

						<VStack alignItems={"start"} spacing={-2}>
							<Text fontSize={"6xl"} fontWeight={"semibold"} color={"white"}>
								$100
							</Text>
							<Text fontSize={"sm"} fontWeight={"semibold"} color={"rgba(255,255,255,0.5)"}>
								Per member, per Year
							</Text>
						</VStack>
					</HStack>

					<Box w={"full"}>
						<Box mt={4} mb={4} height={"1px"} bgColor={"rgba(149,165,166,0.5)"} w={"full"} />
					</Box>

					{/* LIST */}
					<UnorderedList listStyleType={"none"} spacing={2}>
						{features.map(({ text, active }) => (
							<ListItem>
								<HStack spacing={2}>
									<Icon as={FaCheckCircle} boxSize={4} color={active ? "white" : "rgba(255,255,255,0.5)"} />
									<Text color={active ? "white" : "rgba(255,255,255,0.5)"} fontWeight={"normal"} letterSpacing={"wider"} fontSize={"sm"}>
										{text}
										{!active ? (
											<Box borderRadius={3} py={0.5} px={1} fontSize={"2xs"} ml={1} as={"span"} color={"highlight"} borderColor={"highlight"} borderWidth={1} whiteSpace={"nowrap"}>
												Coming Soon
											</Box>
										) : null}
									</Text>
								</HStack>
							</ListItem>
						))}
					</UnorderedList>

					{/* BUTTON */}
					<VStack w={"full"} mt={"10!"}>
						<Button px={16} py={6} bgColor={"highlight"} color={"textPrimary"} fontSize={"lg"} _hover={{
							bgColor: "#F39C12",
						}}>
							Start free 5-day Trial
						</Button>

						<Text color={"white"} fontSize={"sm"}>
							No credit card required
						</Text>
					</VStack>
				</VStack>


				<Flex flex={1} justifyContent={"center"}>
					<VStack flex={0.6} alignItems={"start"} borderColor={"border"} borderWidth={"1px"} px={8} py={6} borderRadius={15}>
						<Box>
							<Icon color={"highlight"} as={FaQuoteLeft} boxSize={10} />
						</Box>
						<Text color={"textPrimary"} fontSize={"xl"} mt={"5!"}>
							Webhook integration has utterly transformed my trading strategy. Missed opportunities are now a thing of the past. Highly recommend!
						</Text>
						<Box mt={"7!"}>
							<Text color={"support"} fontSize={"xs"} fontWeight={"medium"}>
								Alex Rivera, Spain
							</Text>
							<Text color={"support"} fontSize={"2xs"} mt={1}>
								On optimizing trading with seamless automation.
							</Text>
						</Box>

					</VStack>
				</Flex>
			</Flex>
		</VStack>
	);
}