import React from 'react';
import './carousel.css'; // Asegúrate de que este archivo contenga los estilos necesarios

import Binance from "../icon/binance";
import Bitso from "../icon/bitso";
import Bybit from "../icon/bybit";
import Buda from "../icon/buda";
import Cmkt from "../icon/cmkt";

import { Box } from '@chakra-ui/react';

const logos = [
  { Component: Binance, color: "#2C3E50", width: "300px", height: "53px" },
  { Component: Cmkt, color: "#2C3E50", width: "300px", height: "50px" },
  { Component: Bitso, color: "#2C3E50", width: "200px", height: "50px" },
  { Component: Bybit, color: "#2C3E50", width: "130px", height: "50px" },
  { Component: Buda, color: "#2C3E50", width: "200px", height: "50px" },
];

export function Carousel() {
  return (
    <Box className="carousel" overflow="hidden" w="full" bg="highlight" pt="5" pb="5">
      <Box className="scrolling-wrapper">
        {logos.map((logo, index) => (
          <Box pl="2rem" key={index} className="logo" display="inline-flex">
            <logo.Component color={logo.color} width={logo.width} height={logo.height} />
          </Box>
        ))}
        {/* Duplica los logos para un efecto continuo */}
        {logos.map((logo, index) => (
          <Box key={`duplicate-${index}`} className="logo" display="inline-flex">
            <logo.Component color={logo.color} width={logo.width} height={logo.height} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}