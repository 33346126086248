import { Icon } from "@chakra-ui/react";

export default function Bitso(props: any) {
  const { color, ...iconProps } = props;

  return (
    <Icon {...iconProps} viewBox="0 0 109 30">
      <path d="M17.6 21.8C17.6 21.4 17.3 21.2 17 21.2C16.4 21.2 15.6 21.3 15 21.3C11.6 21.3 8.8 18.5 8.8 15.1V1.4V0.2C8.8 0.1 8.7 0 8.6 0H0.1L0 0.1V15C0 23.3 6.7 30 15 30C15.8 30 16.6 29.9 17.4 29.8C17.5 29.8 17.6 29.7 17.6 29.6V21.8Z" fill={color || "#252C36"} />
      <path d="M15 0C14.2 0 13.3 0.1 12.5 0.2C12.4 0.2 12.3 0.3 12.3 0.4V8.2C12.3 8.6 12.6 8.9 12.9 8.8C13.2 8.8 14.9 8.7 14.9 8.7C18.3 8.7 21.1 11.5 21.1 14.9V28.4C21.1 28.5 21.2 28.5 21.2 28.5C26.4 26.2 30 21 30 15C30 6.7 23.3 0 15 0Z" fill={color || "#252C36"} />
      <path d="M63.2 6.20001V2.20001L58.4 2.60001V6.30001H63.2V6.20001ZM72 4.90001L67.4 5.30001V20.6C67.4 23.6 68.8 25.4 72.3 25.4C73.8 25.4 74.9 25.1 76 24.7V21C75 21.4 74.2 21.5 73.3 21.5C72.5 21.5 72.1 21.1 72.1 20.3V12.7H76V8.90001H72V4.90001ZM58.4 25.1H63.2V8.90001H58.4V25.1ZM49.3 21.6C48.7 21.8 47.6 21.8 46.7 21.8C45.2 21.8 43.9 21.8 43.1 21.7V17H46.6C49.1 17 50.2 17.7 50.2 19.4C50.1 20.3 49.8 21 49.3 21.6ZM43 8.80001C43.8 8.80001 44.8 8.70001 45.9 8.70001C48.5 8.70001 49.6 9.50001 49.6 11.1C49.6 12 49.2 12.7 48.4 13.3C47.7 13.4 46.9 13.5 46 13.5C44.9 13.5 43.8 13.5 43 13.4V8.80001ZM51.8 14.7C53.3 13.8 54.6 12.4 54.6 10.3C54.6 6.80001 51.9 4.90001 46.2 4.90001H38.1V25.1H47.5C52.5 25.1 55.1 23.2 55.1 19.4C55.1 16.9 53.7 15.5 51.8 14.7ZM86 15.5C83.6 15.1 83 14.8 83 14.1C83 13.7 83.1 13.4 83.3 13.2C83.9 13 84.9 12.9 85.8 12.9C87.7 12.9 89.4 13.2 90.8 13.6V9.70001C89.7 9.10001 87.9 8.60001 85.4 8.60001C81.2 8.60001 78.5 10.6 78.5 14C78.5 17.3 80.4 18.3 84 18.9C86.6 19.4 87.2 19.7 87.2 20.5C87.2 20.9 87.1 21.1 87 21.3C86.4 21.5 85.4 21.7 84.1 21.7C82.3 21.7 80.2 21.3 79 20.9V24.6C80.3 25.1 82.4 25.4 84.3 25.4C88.8 25.4 91.7 24 91.7 20.4C91.7 17.3 89.8 16.2 86 15.5ZM103.3 21.2C102.7 21.6 102 21.8 101.1 21.8C99.2 21.8 98.1 20.2 98.1 17.4C98.1 15.8 98.4 14.4 99 13.7C99.6 13.2 100.3 13 101.2 13C103.2 13 104.1 14.6 104.1 17C104.2 18.7 103.9 20.2 103.3 21.2ZM101.5 8.60001C96 8.60001 93.5 12.3 93.5 17.4C93.5 22.4 96.4 25.4 101 25.4C106.5 25.4 108.9 21.7 108.9 16.7C108.9 11.7 106.1 8.60001 101.5 8.60001Z" fill={color || "#252C36"} />
    </Icon>
  );
}
