import { VStack } from "@chakra-ui/react";
import { Carousel } from "./carousel"

export function Showcase(): JSX.Element {
  return (
    <VStack w={"full"} pt={48}>
      <Carousel />
    </VStack>
  );
}
