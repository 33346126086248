import { Icon } from "@chakra-ui/react";

export default function Cmkt(props: any) {
  const { color, ...iconProps } = props;

  return (
    <Icon {...iconProps} viewBox="0 0 176 58">
      <g mask="url(#mask0_30_726)">
        <path d="M66.2288 36.3492H74.1977C75.3134 36.3492 76.2696 36.0318 77.0665 35.2381C77.8634 34.4445 78.1822 33.492 78.1822 32.2222C78.1822 31.1112 77.8634 30.1587 77.0665 29.3651C76.2696 28.5714 75.3134 28.2541 74.1977 28.2541H66.2288V36.3492ZM73.2414 14.4444H66.3881V22.0634H73.2414C74.3571 22.0634 75.1541 21.7461 75.9508 20.9524C76.5883 20.1587 77.0665 19.3651 77.0665 18.254C77.0665 17.1428 76.7477 16.1905 75.9508 15.5555C75.1541 14.7619 74.3571 14.4444 73.2414 14.4444ZM80.5728 24.7618C83.6012 26.5079 85.1949 29.2063 85.1949 32.6983C85.1949 35.7143 84.0794 38.0953 82.0073 40C79.9355 41.9047 77.2259 42.8571 74.1977 42.8571H60.491C59.8534 42.8571 59.5347 42.381 59.5347 41.9047V9.0476C59.5347 8.41268 60.0128 8.09521 60.491 8.09521H73.2414C76.2696 8.09521 78.6604 9.0476 80.7322 10.9524C82.8043 12.8571 83.7606 15.0794 83.7606 17.9365C83.7606 20.7936 82.6449 23.0159 80.5728 24.7618Z" fill={color || "#95A5A6"} />
        <path d="M106.392 31.4286V19.0477C106.392 18.4128 106.871 17.9366 107.508 17.9366H111.811C112.449 17.9366 112.927 18.4128 112.927 19.0477V41.7462C112.927 42.3811 112.449 42.8572 111.811 42.8572H107.508C106.871 42.8572 106.392 42.3811 106.392 41.7462V40C104.799 42.3811 102.408 43.4921 98.9016 43.4921C96.1922 43.4921 93.9608 42.5398 92.0481 40.7937C90.2951 38.889 89.3387 36.3492 89.3387 33.1747V18.8889C89.3387 18.254 89.8169 17.7778 90.4545 17.7778H94.7577C95.3953 17.7778 95.8734 18.254 95.8734 18.8889V32.2223C95.8734 33.8096 96.3516 35.0794 97.1483 36.0319C98.1047 36.9841 99.2204 37.3017 100.814 37.3017C102.567 37.3017 103.842 36.8255 104.799 35.7143C105.755 34.9206 106.392 33.4921 106.392 31.4286Z" fill={color || "#95A5A6"} />
        <path d="M125.677 35.5556C127.112 36.8254 128.706 37.4605 130.618 37.4605C132.69 37.4605 134.284 36.8254 135.559 35.5556C136.834 34.2858 137.471 32.5396 137.471 30.4762C137.471 28.4127 136.834 26.8254 135.559 25.3968C134.284 24.127 132.69 23.3333 130.618 23.3333C128.546 23.3333 126.952 23.9682 125.677 25.3968C124.402 26.6668 123.765 28.4127 123.765 30.4762C123.765 32.5396 124.402 34.127 125.677 35.5556ZM137.631 20.9525V10.7937C137.631 10.4762 137.79 10.3175 138.109 10.1588L143.209 8.41275C143.687 8.25402 144.006 8.57149 144.006 9.04767V42.2223C144.006 42.5396 143.687 42.8572 143.369 42.8572H138.268C137.95 42.8572 137.631 42.5396 137.631 42.2223V40.0001C135.718 42.3811 133.009 43.6509 129.502 43.6509C126.156 43.6509 123.287 42.3811 120.896 39.8413C118.505 37.3017 117.39 34.127 117.39 30.4762C117.39 26.8254 118.505 23.8097 120.896 21.1111C123.287 18.5715 126.156 17.3017 129.502 17.3017C133.009 17.3017 135.718 18.5715 137.631 20.9525Z" fill={color || "#95A5A6"} />
        <path d="M156.916 35.5556C158.191 36.8256 159.785 37.4605 161.857 37.4605C163.929 37.4605 165.522 36.8256 166.798 35.5556C168.072 34.2858 168.869 32.5398 168.869 30.4762C168.869 28.4127 168.232 26.8256 166.798 25.397C165.522 24.127 163.929 23.3333 161.857 23.3333C159.785 23.3333 158.191 23.9684 156.916 25.397C155.641 26.6668 155.003 28.4127 155.003 30.4762C155.003 32.5398 155.641 34.127 156.916 35.5556ZM168.869 20.9525V18.7302C168.869 18.4128 169.188 18.0953 169.507 18.0953H174.607C174.926 18.0953 175.245 18.4128 175.245 18.7302V42.2223C175.245 42.5398 174.926 42.8572 174.607 42.8572H169.507C169.188 42.8572 168.869 42.5398 168.869 42.2223V40C166.957 42.3811 164.247 43.6509 160.741 43.6509C157.394 43.6509 154.525 42.3811 152.135 39.8413C149.744 37.3017 148.628 34.127 148.628 30.4762C148.628 26.8256 149.744 23.8096 152.135 21.1113C154.525 18.5715 157.394 17.3016 160.741 17.3016C164.247 17.3016 166.957 18.5715 168.869 20.9525Z" fill={color || "#95A5A6"} />
        <path d="M133.487 57.1429C133.009 57.1429 132.69 56.9841 132.372 56.6668C132.053 56.3492 131.893 56.0319 131.893 55.5555C131.893 55.0794 132.053 54.7619 132.372 54.4445C132.69 54.127 133.009 53.9684 133.487 53.9684C133.965 53.9684 134.284 54.127 134.603 54.4445C134.922 54.7619 135.081 55.0794 135.081 55.5555C135.081 56.0319 134.922 56.3492 134.603 56.6668C134.125 56.9841 133.806 57.1429 133.487 57.1429Z" fill={color || "#95A5A6"} />
        <path d="M141.297 57.1429C140.341 57.1429 139.385 56.9841 138.588 56.508C137.791 56.0316 137.153 55.5555 136.675 54.7618C136.197 53.9682 136.037 53.1745 136.037 52.2222C136.037 51.2698 136.197 50.4761 136.675 49.6825C137.153 48.8888 137.791 48.4127 138.588 47.9365C139.385 47.4602 140.341 47.3016 141.297 47.3016C142.253 47.3016 143.05 47.4602 143.847 47.9365C144.484 48.2539 145.122 48.8888 145.441 49.5239L143.688 50.4761C143.369 50 143.05 49.6825 142.572 49.5239C142.253 49.2063 141.775 49.2063 141.297 49.2063C140.819 49.2063 140.181 49.3651 139.862 49.5239C139.384 49.8412 139.066 50.1588 138.747 50.6349C138.428 51.111 138.428 51.5874 138.428 52.2222C138.428 52.8571 138.588 53.3333 138.747 53.8096C139.066 54.2857 139.384 54.6031 139.862 54.9206C140.341 55.2382 140.819 55.2382 141.297 55.2382C141.775 55.2382 142.253 55.0794 142.572 54.9206C143.05 54.7618 143.369 54.4445 143.688 53.9682L145.441 54.9206C145.122 55.7143 144.484 56.1904 143.847 56.508C143.05 56.8253 142.253 57.1429 141.297 57.1429Z" fill={color || "#95A5A6"} />
        <path d="M151.656 55.0794C152.135 55.0794 152.772 54.9206 153.091 54.7618C153.569 54.4445 153.888 54.1269 154.047 53.6508C154.366 53.1745 154.366 52.6984 154.366 52.0635C154.366 51.4286 154.207 50.9525 154.047 50.4761C153.729 50 153.41 49.6825 153.091 49.3651C152.613 49.2063 152.135 49.2063 151.656 49.2063C151.178 49.2063 150.541 49.3651 150.222 49.5239C149.903 49.6825 149.425 50.1588 149.266 50.6349C148.947 51.111 148.947 51.5874 148.947 52.2222C148.947 52.8571 149.106 53.3333 149.266 53.8096C149.585 54.2857 149.903 54.6031 150.222 54.9206C150.541 55.2382 151.019 55.0794 151.656 55.0794ZM151.656 57.1429C150.7 57.1429 149.744 56.9841 148.947 56.508C148.15 56.0316 147.513 55.5555 147.194 54.7618C146.716 53.9682 146.556 53.1745 146.556 52.2222C146.556 51.2698 146.716 50.4761 147.194 49.6825C147.672 48.8888 148.31 48.4127 148.947 47.9365C149.744 47.4602 150.541 47.3016 151.656 47.3016C152.613 47.3016 153.569 47.4602 154.366 47.9365C155.163 48.4127 155.8 48.8888 156.119 49.6825C156.597 50.4761 156.757 51.2698 156.757 52.2222C156.757 53.1745 156.597 53.9682 156.119 54.7618C155.641 55.5555 155.003 56.0316 154.366 56.508C153.729 56.9841 152.613 57.1429 151.656 57.1429Z" fill={color || "#95A5A6"} />
        <path d="M171.101 47.3016C171.898 47.3016 172.535 47.4602 173.173 47.7778C173.81 48.0953 174.289 48.5714 174.607 49.2063C174.926 49.8412 175.085 50.6349 175.085 51.5874V57.1429H172.854V51.9047C172.854 51.111 172.695 50.4761 172.217 50C171.739 49.5239 171.26 49.3651 170.623 49.3651C170.145 49.3651 169.666 49.5239 169.348 49.6825C169.029 49.8412 168.71 50.1588 168.391 50.6349C168.232 51.111 168.073 51.5874 168.073 52.2222V57.1429H165.841V51.9047C165.841 51.111 165.682 50.4761 165.204 50C164.726 49.5239 164.248 49.3651 163.61 49.3651C163.132 49.3651 162.654 49.5239 162.335 49.6825C162.016 49.8412 161.698 50.1588 161.379 50.6349C161.219 51.111 161.06 51.5874 161.06 52.2222V57.1429H158.829V47.619H160.901V50.1588L160.582 49.3651C160.901 48.7302 161.379 48.2539 162.016 47.9365C162.654 47.619 163.291 47.4602 164.088 47.4602C165.044 47.4602 165.841 47.619 166.479 48.0953C167.116 48.5714 167.595 49.2063 167.754 50.1588L166.798 49.8412C167.116 49.0476 167.595 48.5714 168.391 48.0953C169.188 47.619 170.145 47.3016 171.101 47.3016Z" fill={color || "#95A5A6"} />
        <path d="M28.1365 0C27.6583 0 27.1802 0.15873 26.702 0.317461L2.63559 12.2222C1.20116 12.8571 0.244873 14.4444 0.244873 16.0318V39.0476C0.244873 40.6349 1.04178 42.0635 2.4762 42.8571L22.5581 54.2857C23.1957 54.6033 23.9926 54.9206 24.7896 54.9206C25.5863 54.9206 26.2239 54.7618 26.8614 54.4445L47.2622 43.3333C48.3779 42.6984 49.0153 41.5873 49.0153 40.3176V30.7937C49.0153 29.2063 48.2185 27.7778 46.7841 26.9841L31.4834 18.7302V3.49206C31.4834 1.42857 29.8898 0 28.1365 0ZM20.6456 25.2382L35.3085 33.9682C36.4243 34.6033 36.2649 36.1904 35.3085 36.6667L25.7457 41.9047C25.2677 42.2222 24.6302 42.2222 24.152 41.9047L12.6766 35.3967C12.1984 35.0794 11.8797 34.6033 11.8797 33.9682V21.4286C11.8797 20.7937 12.1984 20.3175 12.836 20L18.4143 17.3016C19.5299 16.8254 20.6456 17.619 20.6456 18.7302V25.2382Z" fill={color || "#95A5A6"} />
      </g>
    </Icon>
  );
}
