import { Box, VStack, Text, Flex } from "@chakra-ui/react";

function Characteristic({
  top,
  title,
  description,
}: {
  top: string;
  title: string | JSX.Element;
  description: string;
}): JSX.Element {
  return (
    <VStack
      pl={[2, 5]}
      pr={[2, 5]}
      pt={[10, 10]}
      w={"full"}
      alignItems={"start"}
      _first={{
        paddingTop: [0, 0, 10],
      }}
    >
      <Box height={"1px"} bgColor={"blackAlpha.200"} w={"full"}></Box>
      <Text
        pt={[5, 5, 10]}
        letterSpacing={"wider"}
        fontWeight={"light"}
        color={"textPrimary"}
        fontSize={"lg"}
        textTransform={"uppercase"}
      >
        {top}
      </Text>
      <Text
        fontWeight={"bold"}
        fontSize={["xl", "xl", "2xl"]}
        color={"blackAlpha.800"}
        lineHeight={"30px"}
        pt={3}
      >
        {title}
      </Text>
      <Text
        fontWeight={"light"}
        fontSize={["xl", "xl", "2xl"]}
        color={"gray.500"}
        letterSpacing={"wider"}
        lineHeight={"30px"}
        pt={2}
      >
        {description}
      </Text>
    </VStack>
  );
}

const characteristics: { top: string; title: string | JSX.Element; description: string }[] = [
  {
    top: "bot",
    title: (
      <Text as={"span"} color="textPrimary">
        <Text as={"span"} backgroundColor={"highlight"}>
          Instant Alerts
        </Text>{" "}
        via Telegram/Discord
      </Text>
    ),
    description: "Receive real-time arbitrage signals directly on Telegram. Experience the cutting-edge in opportunity detection with our advanced AI analyzing multiple markets 24/7.",
  },
  {
    top: "INTEGRATIONS",
    title: (
      <Text as={"span"} color="textPrimary">
        Full Integration with{" "}
        <Text as={"span"} bg={"highlight"}>
          your services
        </Text>
      </Text>
    ),
    description:
      "Customize your workflow with direct signals via webhooks. We make it easy to integrate with your existing systems, allowing for seamless automation.",
  },
  {
    top: "COMMUNITY",
    title: (
      <Text as={"span"} color={"textPrimary"}>
        Trader{" "}
        <Text as={"span"} bgColor={"highlight"}>
          Collaboration Hub
        </Text>
      </Text>
    ),
    description:
      "Begin your arbitrage journey, discovering rich opportunities. Gain insights, refine strategies, and join our community for success in the dynamic market.",
  },
];

export function Characteristics(): JSX.Element {
  return (
    <VStack bgColor={"white"} pl={5} pr={5}>
      <Text
        pt={20}
        pb={10}
        lineHeight={"24px"}
        fontSize={["3xl", "4xl"]}
        color={"textPrimary"}
      >
        Why{" "}
        <Text as="span" bgColor="highlight">
          Artibrage Ninja
        </Text>
        {" "}?
      </Text>
      <Flex alignItems={"start"} direction={["column", "column", "row"]}>
        {characteristics.map(({ top, title, description }, index) => (
          <Characteristic key={index} top={top} title={title} description={description} />
        ))}
      </Flex>
    </VStack>
  );
}
