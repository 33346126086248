import { Icon, Flex } from "@chakra-ui/react";
import LogoIcon from "../icon/logo-white";

export function Navbar() {
  return (
    <Flex
      w={"full"}
      position={"absolute"}
      direction={"row"}
      pl={[8, 12]}
      pr={[7, 10]}
      pt={[4, 5]}
      pb={[4, 5]}
      zIndex={"1"}
    >
      <Icon
        as={LogoIcon}
        w={["110px", "110px", "155px"]}
        h={["55px", "55px", "66px"]}
      />

    </Flex>
  );
}
