import { Icon } from "@chakra-ui/react";

export default function Bybit(props: any) {
  const { color, ...iconProps } = props;

  return (
    <Icon {...iconProps} viewBox="0 0 170 57">
      <path d="M120.908 43.8214V0.451538H129.633V43.8214H120.908Z" fill={color || "#95A5A6"} />
      <path d="M19.2908 56.7014H0.602051V13.3315H18.5332C27.2577 13.3315 32.3316 18.0841 32.3316 25.5229C32.3316 30.3443 29.0714 33.4438 26.8214 34.4769C29.5077 35.6938 32.9745 38.4259 32.9745 44.2346C32.9745 52.3162 27.2577 56.7014 19.2908 56.7014ZM17.8674 20.8851H9.3495V30.8724H17.8674C21.5638 30.8724 23.6301 28.8519 23.6301 25.8673C23.6301 22.8826 21.5638 20.8851 17.8674 20.8851ZM18.4184 38.4718H9.32654V49.1249H18.4184C22.3674 49.1249 24.25 46.6912 24.25 43.7525C24.25 40.8137 22.3674 38.4718 18.4184 38.4718Z" fill={color || "#95A5A6"} />
      <path d="M59.5612 38.9081V56.7014H50.9056V38.9081L37.4745 13.3315H46.9337L55.2679 30.8035L63.4872 13.3315H72.9464L59.5612 38.9081Z" fill={color || "#95A5A6"} />
      <path d="M97.6964 56.7014H79.0076V13.3315H96.9388C105.663 13.3315 110.737 18.0841 110.737 25.5229C110.737 30.3443 107.477 33.4438 105.227 34.4769C107.913 35.6938 111.38 38.4259 111.38 44.2346C111.357 52.3162 105.663 56.7014 97.6964 56.7014ZM96.25 20.8851H87.7321V30.8724H96.25C99.9464 30.8724 102.013 28.8519 102.013 25.8673C102.013 22.8826 99.9464 20.8851 96.25 20.8851ZM96.801 38.4718H87.7092V49.1249H96.801C100.75 49.1249 102.633 46.6912 102.633 43.7525C102.633 40.8137 100.773 38.4718 96.801 38.4718Z" fill={color || "#95A5A6"} />
      <path d="M157.712 20.8851V56.7014H148.987V20.8851H137.347V13.3315H169.375V20.8851H157.712Z" fill={color || "#95A5A6"} />
    </Icon>
  );
}
