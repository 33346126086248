import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    primary: '#4871B5',
    textPrimary: '#2C3E50',
    textSecondary: '#95A5A6',
    highlight: '#FFD700',
    support: '#7F8C8D',
    complementary: {
      orange: '#F39C12',
      redOrange: '#E74C3C',
    },
    border: "#CCD2E3"
  },
});

export default theme;