import { Icon } from "@chakra-ui/react";

export default function Binance(props: any) {
  const { color, ...iconProps } = props;

  return (
    <Icon {...iconProps} viewBox="0 0 302 61">
      <path d="M19.1187 25.254L30.79 13.5832L42.4674 25.2602L49.2586 18.4691L30.79 0L12.3276 18.4629L19.1187 25.254Z" fill={color || "#95A5A6"} />
      <path d="M14.3229 30.0484L7.53186 23.2573L0.740495 30.0487L7.53153 36.8397L14.3229 30.0484Z" fill={color || "#95A5A6"} />
      <path d="M19.1187 34.8448L30.79 46.5156L42.467 34.8391L49.2619 41.6265L49.2586 41.6303L30.79 60.0988L12.3271 41.6364L12.3176 41.6269L19.1187 34.8448Z" fill={color || "#95A5A6"} />
      <path d="M54.0488 36.8429L60.8402 30.0515L54.0492 23.2605L47.2578 30.0519L54.0488 36.8429Z" fill={color || "#95A5A6"} />
      <path d="M37.6785 30.0458H37.6813L30.79 23.1545L25.6972 28.2473H25.6968L25.112 28.8326L23.9049 30.0397L23.8954 30.0492L23.9049 30.0591L30.79 36.9443L37.6813 30.053L37.6846 30.0492L37.6785 30.0458Z" fill={color || "#95A5A6"} />
      <path d="M71.4272 14.5625H86.1976C89.8635 14.5625 92.6356 15.5033 94.5144 17.3849C95.9683 18.8422 96.695 20.6483 96.695 22.8028V22.894C96.695 23.8044 96.5825 24.609 96.3561 25.3067C96.1302 26.0054 95.8283 26.6344 95.4509 27.1959C95.0745 27.7579 94.6369 28.2511 94.1394 28.6755C93.6415 29.1008 93.1065 29.4653 92.5336 29.7682C94.3763 30.4669 95.8269 31.4148 96.8844 32.6138C97.9415 33.8133 98.4708 35.4752 98.4708 37.5993V37.69C98.4708 39.1472 98.1907 40.4217 97.6302 41.5149C97.0696 42.6071 96.2664 43.518 95.2207 44.2467C94.175 44.9753 92.9176 45.5212 91.448 45.8857C89.9784 46.2498 88.3498 46.4316 86.5627 46.4316H71.4272V14.5625ZM84.7209 27.4466C86.2693 27.4466 87.4987 27.1817 88.4087 26.6501C89.3191 26.1189 89.7743 25.2612 89.7743 24.0778V23.9867C89.7743 22.9248 89.3794 22.1131 88.5905 21.5511C87.8011 20.9896 86.6628 20.7086 85.1762 20.7086H78.2559V27.4466H84.7209ZM86.5878 40.286C88.1357 40.286 89.349 40.0059 90.23 39.4439C91.11 38.8824 91.5505 38.0095 91.5505 36.8256V36.735C91.5505 35.6726 91.1404 34.8306 90.3211 34.2078C89.5014 33.5864 88.1813 33.2751 86.36 33.2751H78.2559V40.2865L86.5878 40.286Z" fill={color || "#95A5A6"} />
      <path d="M107.267 14.5625H114.279V46.4325H107.267V14.5625Z" fill={color || "#95A5A6"} />
      <path d="M124.899 14.5625H131.363L146.297 34.1855V14.5625H153.217V46.4325H147.253L131.819 26.1725V46.4325H124.899V14.5625Z" fill={color || "#95A5A6"} />
      <path d="M174.445 14.3351H180.909L194.568 46.4325H187.238L184.324 39.2849H170.848L167.934 46.4325H160.785L174.445 14.3351ZM181.82 33.0923L177.585 22.7577L173.352 33.0923H181.82Z" fill={color || "#95A5A6"} />
      <path d="M202.137 14.5625H208.603L223.535 34.1855V14.5625H230.456V46.4325H224.492L209.057 26.1725V46.4325H202.137V14.5625Z" fill={color || "#95A5A6"} />
      <path d="M255.689 46.9784C253.351 46.9784 251.182 46.5531 249.178 45.7039C247.174 44.8547 245.445 43.6931 243.988 42.2207C242.531 40.7487 241.392 39.011 240.574 37.0078C239.754 35.0043 239.344 32.8644 239.344 30.5879V30.4972C239.344 28.2207 239.754 26.0889 240.574 24.1005C241.392 22.1126 242.531 20.3672 243.988 18.8649C245.444 17.3626 247.19 16.1782 249.223 15.3134C251.256 14.4485 253.504 14.0161 255.962 14.0161C257.448 14.0161 258.806 14.1381 260.036 14.3802C261.265 14.6237 262.38 14.9569 263.383 15.3817C264.385 15.8071 265.309 16.323 266.16 16.9297C267.009 17.5372 267.799 18.2046 268.527 18.9333L264.066 24.0778C262.82 22.9552 261.554 22.0742 260.264 21.4372C258.973 20.8001 257.525 20.4812 255.916 20.4812C254.58 20.4812 253.343 20.7394 252.206 21.2554C251.068 21.7713 250.088 22.4843 249.269 23.3947C248.45 24.3051 247.812 25.3603 247.356 26.5589C246.902 27.7584 246.675 29.0405 246.675 30.4061V30.4968C246.675 31.8624 246.902 33.153 247.356 34.3663C247.812 35.581 248.441 36.6428 249.246 37.5532C250.05 38.4637 251.022 39.1852 252.159 39.7163C253.299 40.248 254.55 40.5128 255.916 40.5128C257.738 40.5128 259.277 40.1791 260.536 39.5113C261.796 38.8444 263.049 37.9335 264.293 36.7795L268.755 41.287C267.935 42.1675 267.084 42.9569 266.206 43.6542C265.325 44.3529 264.361 44.952 263.314 45.4532C262.267 45.9535 261.12 46.3338 259.877 46.591C258.632 46.8488 257.236 46.9784 255.689 46.9784Z" fill={color || "#95A5A6"} />
      <path d="M277.007 14.5625H301V20.8002H283.927V27.2652H298.952V33.5024H283.927V40.1953H301.229V46.4325H277.007V14.5625Z" fill={color || "#95A5A6"} />
    </Icon>
  );
}
